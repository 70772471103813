import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineMicrophone } from 'react-icons/hi';
import { PiWarningCircleFill } from 'react-icons/pi';
import cn from 'classnames';
import style from './controls.module.scss';

type MicrophoneProps = {
  isActive: boolean;
  isHasAccess: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Microphone: FC<MicrophoneProps> = ({ isActive, isHasAccess, setIsActive }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleToggleMicrophone = () => {
    setIsActive(state => !state);
  };

  return (
    <button
      className={cn(style.button, { [style.button__off]: !isActive || !isHasAccess })}
      title={t('controls.mic', { status: isActive ? t('off') : t('on') })}
      disabled={!isHasAccess}
      onClick={handleToggleMicrophone}
    >
      {!isHasAccess && (
        <PiWarningCircleFill className={style.warning} color='#facc15' size='20px' title={t('micNotFound')} />
      )}
      <HiOutlineMicrophone size='20px' color='black' />
    </button>
  );
};
