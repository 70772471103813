import React, { FC, useEffect, useMemo, useState } from 'react';
import { VideoStream } from 'components';
import { SelectDevices, StreamOptions } from '../';
import style from './my-stream.module.scss';

type GetStream = {
  deviceId: string;
  setStream: React.Dispatch<React.SetStateAction<MediaStream | null>>;
  setIsHasAccess: React.Dispatch<React.SetStateAction<boolean>>;
};

const getAudioStream = async ({ deviceId, setStream, setIsHasAccess }: GetStream) => {
  try {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId },
    });
    setStream(mediaStream);
    setIsHasAccess(true);
  } catch (e) {
    console.error(e);
  }
};
const getVideoStream = async ({ deviceId, setStream, setIsHasAccess }: GetStream) => {
  try {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId /* width: { ideal: 3840 }, height: { ideal: 2160 }  */ },
    });
    setStream(mediaStream);
    setIsHasAccess(true);
  } catch (e) {
    console.error(e);
  }
};

type MyStreamProps = {
  isActiveMicrophone: boolean;
  isActiveVideo: boolean;
  isHasAccessMicrophone: boolean;
  isHasAccessVideo: boolean;
  videoDeviceId: string;
  audioDeviceId: string;
  setVideoDeviceId: React.Dispatch<React.SetStateAction<string>>;
  setAudioDeviceId: React.Dispatch<React.SetStateAction<string>>;
  setIsHasAccessMicrophone: React.Dispatch<React.SetStateAction<boolean>>;
  setIsHasAccessVideo: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActiveMicrophone: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActiveVideo: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFinishCreateStream: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MyStream: FC<MyStreamProps> = ({
  isActiveMicrophone,
  isActiveVideo,
  isHasAccessMicrophone,
  isHasAccessVideo,
  videoDeviceId,
  audioDeviceId,
  setVideoDeviceId,
  setAudioDeviceId,
  setIsHasAccessMicrophone,
  setIsHasAccessVideo,
  setIsActiveMicrophone,
  setIsActiveVideo,
  setIsFinishCreateStream,
}) => {
  const [stream, setStream] = useState<MediaStream | null>(null);

  const streamToShow = useMemo(() => {
    const videoTracks = stream?.getVideoTracks();
    if (!videoTracks) return null;
    const streamToShow = new MediaStream(videoTracks);

    return streamToShow;
  }, [stream]);

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: videoDeviceId,
            width: { ideal: 1920 },
            height: { ideal: 1080 } /*  width: { max: 3840 }, height: { max: 2160 } */,
          },
          audio: { deviceId: audioDeviceId },
        });

        setIsHasAccessMicrophone(true);
        setIsHasAccessVideo(true);

        setStream(mediaStream);
      } catch (e) {
        //так как мы не получили стрим с видео и аудио, надо попробовать получить их поотдельности
        await getAudioStream({ deviceId: audioDeviceId, setStream, setIsHasAccess: setIsHasAccessMicrophone });
        await getVideoStream({ deviceId: videoDeviceId, setStream, setIsHasAccess: setIsHasAccessVideo });

        console.error(e);
      }

      setIsFinishCreateStream(true);
    };

    asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoDeviceId, audioDeviceId]);

  useEffect(() => {
    if (!stream) return;

    stream.getVideoTracks().forEach(item => (item.enabled = isActiveVideo));
  }, [stream, isActiveVideo]);

  useEffect(() => {
    return () => {
      stream?.getTracks()?.forEach(track => {
        track.stop();
      });
    };
  }, [stream]);

  return (
    <>
      <div className={style.block}>
        <VideoStream classNames={{ container: style.video }} title='' stream={streamToShow}>
          <StreamOptions
            isActiveMicrophone={isActiveMicrophone}
            isActiveVideo={isActiveVideo}
            isHasAccessMicrophone={isHasAccessMicrophone}
            isHasAccessVideo={isHasAccessVideo}
            setIsActiveMicrophone={setIsActiveMicrophone}
            setIsActiveVideo={setIsActiveVideo}
          />
        </VideoStream>
      </div>
      {stream && (
        <SelectDevices
          videoDeviceId={videoDeviceId}
          audioDeviceId={audioDeviceId}
          setVideoDeviceId={setVideoDeviceId}
          setAudioDeviceId={setAudioDeviceId}
        />
      )}
    </>
  );
};
