import { useState, useEffect, useCallback } from 'react';
import Janus, { type JanusJS } from 'janus-gateway';

type MessageListparticipantsRes = {
  videoroom: 'participants';
  room: number;
  participants: {
    id: number;
    display: string;
    publisher: boolean;
    talking: boolean;
  }[];
};

type Stream = {
  mid: string;
  type: 'video' | 'audio';
};
type Publisher = {
  id: string;
  display: string;
  streams: Stream[];
};
type RemoteStream = Omit<Publisher, 'streams'> & {
  pluginHandle: JanusJS.PluginHandle;
  stream: MediaStream;
};
export type RemoteStreams = Record<string, RemoteStream>;

const opaqueId = 'videoroomtest-' + Janus.randomString(12);
Janus.init({});

type UseJanusParams = {
  displayName: string | undefined;
  roomId: number;
  videoDeviceId: string;
  audioDeviceId: string;
  isHasAccessMicrophone: boolean;
  isHasAccessVideo: boolean;
};

export const useJanus = ({
  displayName,
  roomId,
  videoDeviceId,
  audioDeviceId,
  isHasAccessMicrophone,
  isHasAccessVideo,
}: UseJanusParams) => {
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [pluginHandle, setPluginHandle] = useState<JanusJS.PluginHandle | null>(null);
  const [remoteStreams, setRemoteStreams] = useState<RemoteStreams>({});

  const newRemoteFeed = useCallback(
    (janus: Janus, privateId: string, publisher: Publisher) => {
      let pluginHandle: JanusJS.PluginHandle;
      janus.attach({
        plugin: 'janus.plugin.videoroom',
        opaqueId: opaqueId,
        success: _pluginHandle => {
          pluginHandle = _pluginHandle;
          pluginHandle.send({
            message: {
              request: 'join',
              room: roomId,
              ptype: 'subscriber',
              streams: publisher.streams.map(({ mid }) => ({ feed: publisher.id, mid })),
              private_id: privateId,
            },
            error: e => {
              console.error(e);
            },
          });
        },
        onmessage: (msg, jsep) => {
          const event = msg.videoroom;
          if (event === 'attached') {
            // ...
          } else if (event === 'event') {
            // ...
          }

          if (jsep) {
            const stereo = jsep.sdp?.indexOf('stereo=1') !== -1;
            pluginHandle.createAnswer({
              jsep: jsep,
              // We only specify data channels here, as this way in
              // case they were offered we'll enable them. Since we
              // don't mention audio or video tracks, we autoaccept them
              // as recvonly (since we won't capture anything ourselves)
              // @ts-ignore
              tracks: [{ type: 'data' }],
              customizeSdp: (jsep: any) => {
                if (stereo && jsep.sdp.indexOf('stereo=1') === -1) {
                  jsep.sdp = jsep.sdp.replace('useinbandfec=1', 'useinbandfec=1;stereo=1');
                }
              },
              success: jsep => {
                pluginHandle.send({ message: { request: 'start', room: roomId }, jsep: jsep });
              },
              error: e => {
                console.error(e);
              },
            });
          }
        },
        onremotetrack: (track, mid, isOn, metadata) => {
          setRemoteStreams(remoteStreams => {
            const stream =
              remoteStreams[publisher.id]?.stream ||
              new MediaStream([
                /* track */
              ]);
            if (isOn) {
              stream.addTrack(track);
            } else {
              stream.removeTrack(track);
            }

            return {
              ...remoteStreams,
              [publisher.id]: {
                id: publisher.id,
                display: publisher.display,
                pluginHandle,
                stream,
              },
            };
          });
        },

        error: e => {
          console.error(e);
        },
      });
    },
    [roomId],
  );

  useEffect(() => {
    if (!roomId || !displayName) return;

    let pluginHandle: JanusJS.PluginHandle;
    //let myId: string;
    let myPrivateId: string;

    const janus = new Janus({
      server: process.env.REACT_APP_API_JANUS_URL!,
      success: () => {
        janus.attach({
          plugin: 'janus.plugin.videoroom',
          opaqueId: opaqueId,
          success: _pluginHandle => {
            pluginHandle = _pluginHandle;
            setPluginHandle(_pluginHandle);
            pluginHandle.send({
              message: {
                request: 'join',
                room: roomId,
                ptype: 'publisher',
                display: displayName,
              },
            });
          },
          onmessage: (msg, jsep) => {
            const event = msg.videoroom;
            if (event === 'joined') {
              //myId = msg.id;
              myPrivateId = msg.private_id;

              const tracks: JanusJS.TrackOption[] = [];

              if (isHasAccessMicrophone) {
                tracks.push({
                  type: 'audio',
                  recv: false,
                  //@ts-ignore
                  capture: { deviceId: audioDeviceId },
                });
              }
              if (isHasAccessVideo) {
                tracks.push({
                  type: 'video',
                  recv: false,
                  simulcast: false,
                  capture: {
                    //@ts-ignore
                    deviceId: videoDeviceId,
                    width: { ideal: 1920 },
                    height: { ideal: 1080 },
                  },
                });
              }

              // Publish own feed
              pluginHandle.createOffer({
                tracks,
                success: jsep => {
                  pluginHandle.send({
                    message: { request: 'configure', bitrate: 0, audio: true, video: true },
                    jsep: jsep,
                  });
                },
              });
            } else if (event === 'event') {
              if (msg.unpublished) {
                const publisherId = msg.unpublished;
                setRemoteStreams(remoteStreams => {
                  const newRemoteStreams = { ...remoteStreams };
                  remoteStreams[publisherId]?.pluginHandle?.detach();
                  delete newRemoteStreams[publisherId];
                  return newRemoteStreams;
                });
              } else if (msg.leaving) {
                const leavingId = msg.leaving;
                setTimeout(() => {
                  setRemoteStreams(remoteStreams => {
                    const newRemoteStreams = { ...remoteStreams };
                    remoteStreams[leavingId]?.pluginHandle?.detach();
                    delete newRemoteStreams[leavingId];
                    return newRemoteStreams;
                  });
                });
              }
            }

            // Handle existing remote sessions
            const publishers = msg.publishers;
            if (publishers) {
              publishers.forEach((item: any) => newRemoteFeed(janus, myPrivateId, item));
            }

            if (jsep) {
              pluginHandle.handleRemoteJsep({ jsep: jsep });
            }
          },
          onlocaltrack: (track, isEnabled) => {
            if (isEnabled) {
              if (track.kind === 'video') {
                setStream(new MediaStream([track]));
              }
            } else {
              setStream(null);
            }
          },
        });
        //
      },
    });

    const interval = setInterval(() => {
      pluginHandle.send({
        message: { request: 'listparticipants', room: roomId },
        success: (data: MessageListparticipantsRes) => {
          const { participants } = data;
          participants.forEach(({ publisher, id }) => {
            if (!publisher) {
              setRemoteStreams(state => {
                if (state[id]) {
                  const newState = { ...state };
                  delete newState[id];
                  return newState;
                }
                return state;
              });
            }
          });
        },
      });
    }, 4000);

    return () => {
      janus.destroy({ cleanupHandles: true });
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayName, roomId, newRemoteFeed]);

  return { stream, remoteStreams, pluginHandle, setRemoteStreams };
};
