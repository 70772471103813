import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Socket } from 'socket.io-client';
import { useGetWindowSize } from 'hooks';
import { type RemoteStreams } from 'hooks/use-janus';
import { useGetVisibleCameraControls } from 'pages/meeting/use-get-is-visible-camera-controls';
import { VideoStream, PositionControl } from 'components';
import style from './streams.module.scss';

type MainStreamProps = {
  socket: Socket | null;
  selectStreamId: string;
  userName: string;
  myStream: MediaStream | null;
  remoteStreams: RemoteStreams;
  isShowVisualEffects: boolean;
  isFullscreen: boolean;
};

export const MainStream: FC<MainStreamProps> = ({
  socket,
  selectStreamId,
  userName,
  myStream,
  remoteStreams,
  isShowVisualEffects,
  isFullscreen,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const { isMobile } = useGetWindowSize();
  const { isVisibleCameraControls } = useGetVisibleCameraControls({ myStream, remoteStreams, selectStreamId });
  const [mainStream, setMainStream] = useState<MediaStream | null>(null);
  const [title, setTitle] = useState('');

  const myStreamId = myStream?.id;
  const isActiveMyStream = myStreamId === selectStreamId;

  useEffect(() => {
    if (isActiveMyStream) {
      setMainStream(myStream);
      setTitle(`${t('you')}: ${userName}`);
    } else {
      const remoteStreamsArr = Object.values(remoteStreams);
      remoteStreamsArr.forEach(item => {
        const { stream, display } = item;
        const streamId = stream.id;

        if (streamId === selectStreamId) {
          setMainStream(stream);
          setTitle(display);
        }
      });
    }
  }, [t, isActiveMyStream, selectStreamId, userName, myStream, remoteStreams]);

  const handleClickVideo: React.MouseEventHandler<HTMLVideoElement> = e => {
    //TODO: вроде не используется
    if (isActiveMyStream) return;

    const { clientWidth: containerWidth, clientHeight: containerHeight, videoWidth, videoHeight } = e.currentTarget;

    const originalResolution = videoWidth / videoHeight;
    const containedVideoWidth = originalResolution * containerHeight;
    const offsetWidth = (containerWidth - containedVideoWidth) / 2;

    const { offsetX, offsetY } = e.nativeEvent;
    const x = offsetX - offsetWidth;

    if (x >= 0 && x <= containedVideoWidth) {
      const coordinatesX = ((x / containedVideoWidth) * 2 - 1) * 100;
      const coordinatesY = ((offsetY / containerHeight) * -2 + 1) * 100;

      const dx = Math.round(coordinatesX);
      const dy = Math.round(coordinatesY);
      socket?.emit('move', [dx, dy]);
    }
  };

  const handleClickElements: React.MouseEventHandler<HTMLDivElement> = e => {
    const { clientWidth, clientHeight } = e.currentTarget;
    const { offsetX, offsetY } = e.nativeEvent;

    const coordinatesX = ((offsetX / clientWidth) * 2 - 1) * 100;
    const coordinatesY = ((offsetY / clientHeight) * -2 + 1) * 100;

    const dx = Math.round(coordinatesX);
    const dy = Math.round(coordinatesY);
    socket?.emit('move', [dx, dy]);
  };

  const isActivePositionControl = isMobile && !isActiveMyStream;

  return (
    <div className={style.mainStreamBlock}>
      {mainStream && (
        <VideoStream
          classNames={{ container: style.videoContainer }}
          title={title}
          stream={mainStream}
          isShowElements={!isActiveMyStream && isShowVisualEffects && isVisibleCameraControls}
          onClickVideo={!isActivePositionControl ? handleClickVideo : undefined}
          onClickElements={!isActivePositionControl ? handleClickElements : undefined}
        >
          {isActivePositionControl && socket && <PositionControl socket={socket} isFullscreen={isFullscreen} />}
        </VideoStream>
      )}
    </div>
  );
};
